import React, { useState } from "react";
import { Link, graphql } from "gatsby";

export default ({ nodes }) => {
  return (
    <div className="flex flex-row w-full p-5 justify-center">
      {nodes && (
        <div className="flex-wrap flex-col content-center items-center justify-center">
          {nodes.map((p) => {
            console.log("post:", p);
            return (
              <Link key={p.id} to={`/posts/${p.slug}`}>
                <div className="flex max-w-md flex-col p-2 border-4 border-black m-2 hover:shadow-md hover:bg-black hover:text-white">
                  <h1 className="font-bold text-xl ">{p.frontmatter.title}</h1>
                  <h1 className="text-base">{p.frontmatter.description}</h1>
                  <h1 className="text-base font-bold text-gray-500">
                    {p.frontmatter.date}
                  </h1>
                </div>
              </Link>
            );
          })}
        </div>
      )}
    </div>
  );
};