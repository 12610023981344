import React, { useState } from "react";
import PhoneInputCard from "../components/BetaCard";
import Header from "../components/Header";
import PostList from "../components/PostList";
import { graphql } from "gatsby";

const IndexPage = ({ data }) => {
  console.log("got graphql data:", data);
  const onPhoneSubmit = (phone) => {
    console.log("Sending a text with the beta link to your phone");
  };
  return (
    <main className="flex flex-col w-full h-full bg-white text-black">
      <Header large={false} />
      <div className="flex flex-col bg-gray-100 h-full items-center">
        <div className="flex flex-col w-full md:pt-10">
          <div className="flex flex-row flex-wrap justify-center items-center sm:flex-nowrap p-5">
            <div className="flex flex-col p-5 md:w-1/2">
              <h1 className="self-center text-4xl font-bold">
                Gigbox is a research and development toolkit centering workers
                in the gig economy.
              </h1>
              <p className="pt-1">
                Gigbox aims to help workers collect + pool data about their
                experience to help them build power, help researchers understand
                the gig economy more fully, and help advocates more effectively
                plan for the future of work.
              </p>
            </div>
            <div className="flex-initial flex-row max-w-48">
              <PhoneInputCard submitPhone={onPhoneSubmit} learnMore={true} />
            </div>
          </div>
        </div>
        <div className="flex flex-col sm:w-2/3 md:pt-10">
          <div className="flex flex-col justify-center items-center sm:flex-nowrap p-5 ">
            <h1 className="text-3xl font-bold self-start">Updates</h1>
            <PostList nodes={data.allMdx.nodes} />
          </div>
        </div>
      </div>
    </main>
  );
};

export const query = graphql`
  query IndexQueryPosts {
    allMdx(
      filter: { frontmatter: { templateKey: { in: ["updates"] } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        id
        body
        slug
        frontmatter {
          description
          title
          date(formatString: "MMMM D, YYYY")
        }
      }
    }
  }
`;

export default IndexPage;
